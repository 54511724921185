export const browsers = [
  {
    label: 'facebook',
    value: 'facebook'
  },
  {
    label: 'instagram app',
    value: 'instagram app'
  },
  {
    label: 'chrome mobile',
    value: 'chrome mobile'
  },
  {
    label: 'mobile safari',
    value: 'mobile safari'
  },
  {
    label: 'chrome webview',
    value: 'chrome webview'
  },
  {
    label: 'baidu spark',
    value: 'baidu spark'
  },
  {
    label: 'chrome',
    value: 'chrome'
  },
  {
    label: 'samsung browser',
    value: 'samsung browser'
  },
  {
    label: 'microsoft edge',
    value: 'microsoft edge'
  },
  {
    label: 'safari',
    value: 'safari'
  },
  {
    label: 'miui browser',
    value: 'miui browser'
  },
  {
    label: 'chrome mobile ios',
    value: 'chrome mobile ios'
  },
  {
    label: 'firefox',
    value: 'firefox'
  },
  {
    label: 'whatsapp',
    value: 'whatsapp'
  },
  {
    label: 'android browser',
    value: 'android browser'
  },
  {
    label: 'opera',
    value: 'opera'
  },
  {
    label: 'internet explorer',
    value: 'internet explorer'
  }
]
