<script setup>
import { reactive, ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import CampaignAdvancedForm from '@/components/partials/campaign/CampaignAdvancedForm.vue'
import { useCampaignStore } from '@/stores/campaign.js'
import CampaignBasicForm from '@/components/partials/campaign/CampaignBasicForm.vue'

const campaignStore = useCampaignStore()
const { t } = useI18n()
useHead({
  title: t('pages.campaign.edit.title')
})

const isSaving = ref(false)
const errors = ref({})
const form = reactive({
  name: '',
  active: true,
  pages: {
    white: {
      type: 'content',
      content: ''
    },
    /**
     * @deprecated use `offers` instead
     */
    offer: {
      type: 'redirect',
      content: ''
    },
    offers: [
      {
        content: '',
        share: 100,
        type: 'redirect'
      }
    ]
  },
  mode: 'basic',
  alias: null,
  domain: null,
  filters: {
    deny_always: false,
    facebook: false,
    google: false,
    bots: false,
    tiktok: false,
    proxy: false,
    kwai: false,
    adspy: false,
    cloakup_ai: false,
    browser_language: {
      allow: false,
      languages: []
    },
    geolocation: {
      allow: true,
      countries: []
    },
    device: {
      allow: true,
      devices: []
    },
    referer: {
      block_null: false,
      allow: true,
      domains: []
    },
    query: {
      allow: true,
      params: {},
      condition: 'some',
      remove_params: false,
      rules: {}
    },
    domain: {
      allow: true,
      domains: []
    },
    isp: {
      allow: true,
      isps: []
    },
    browser: {
      allow: true,
      browsers: []
    },
    user_agent: {
      allow: true,
      user_agents: []
    },
    os: {
      allow: true,
      os: []
    },
    blacklist: [],
    whitelist: []
  }
})

async function updateCampaign(id, campaign) {
  errors.value = {}
  isSaving.value = true

  try {
    await campaignStore.update(id, campaign)

    campaignStore.toggleParamsConfig()

    toast.success(t('pages.campaign.edit.toast.success.title'), {
      description: t('pages.campaign.edit.toast.success.description'),
    })
  } catch (e) {
    if (e.response?.status === 422) {
      errors.value = e.response.data.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isSaving.value = false
  }
}

watch(() => campaignStore.campaign, () => {
  if (!campaignStore.campaign) {
    return
  }

  form.name = campaignStore.campaign.name
  form.mode = campaignStore.campaign.mode

  // @deprecated
  if (campaignStore.campaign.pages.offer) {
    form.pages.offers = [
      {
        type: campaignStore.campaign.pages.offer.type,
        content: campaignStore.campaign.pages.offer.content,
        share: 100
      }
    ]
  } else {
    if (campaignStore.campaign.pages.offers.length > 0) {
      form.pages.offers = campaignStore.campaign.pages.offers
    } else {
      form.pages.offers = [
        {
          type: 'redirect',
          content: '',
          share: 100
        }
      ]
    }
  }

  form.filters.tiktok = campaignStore.campaign.filters?.tiktok ?? false
  form.filters.proxy = campaignStore.campaign.filters?.proxy ?? false
  form.filters.facebook = campaignStore.campaign.filters?.facebook ?? false
  form.filters.google = campaignStore.campaign.filters?.google ?? false
  form.filters.bots = campaignStore.campaign.filters?.bots ?? false
  form.filters.kwai = campaignStore.campaign.filters?.kwai ?? false
  form.filters.cloakup_ai = campaignStore.campaign.filters?.cloakup_ai ?? false
  form.filters.adspy = campaignStore.campaign.filters?.adspy ?? false

  if (campaignStore.campaign.filters?.cloakup) {
    form.filters.facebook = true
    form.filters.google = true
    form.filters.bots = true
    form.filters.cloakup_ai = true
  }

  form.pages.white.content = campaignStore.campaign.pages.white.content
  form.pages.white.type = campaignStore.campaign.pages.white.type

  form.filters.deny_always = campaignStore.campaign.filters.deny_always
  form.filters.referer.block_null = campaignStore.campaign.filters.referer.block_null

  form.filters.referer.allow = campaignStore.campaign.filters.referer.allow
  form.filters.referer.domains = campaignStore.campaign.filters.referer.domains

  form.filters.query.allow = campaignStore.campaign.filters.query.allow
  form.filters.query.params = campaignStore.campaign.filters.query.params
  form.filters.query.condition = campaignStore.campaign.filters.query?.condition || 'some'
  form.filters.query.remove_params = campaignStore.campaign.filters.query.remove_params || false
  form.filters.query.rules = campaignStore.campaign.filters.query.rules || {}

  form.filters.isp.allow = campaignStore.campaign.filters.isp?.allow ?? true
  form.filters.isp.isps = campaignStore.campaign.filters.isp?.isps ?? []

  form.filters.user_agent.allow = campaignStore.campaign.filters.user_agent?.allow ?? true
  form.filters.user_agent.user_agents
		= campaignStore.campaign.filters.user_agent?.user_agents ?? []

  form.filters.device.allow = campaignStore.campaign.filters.device.allow ?? true
  form.filters.device.devices = campaignStore.campaign.filters.device.devices

  form.filters.geolocation.allow = campaignStore.campaign.filters.geolocation.allow
  form.filters.geolocation.countries = campaignStore.campaign.filters.geolocation.countries

  form.filters.domain.allow = campaignStore.campaign.filters.domain.allow
  form.filters.domain.domains = campaignStore.campaign.filters.domain.domains

  form.filters.os.allow = campaignStore.campaign.filters.os?.allow ?? false
  form.filters.os.os
		= campaignStore.campaign.filters.os?.os ?? []

  form.filters.blacklist = campaignStore.campaign.filters?.blacklist ?? []
  form.filters.whitelist = campaignStore.campaign.filters?.whitelist ?? []

  form.filters.browser_language.languages
		= campaignStore.campaign.filters.browser_language?.languages ?? []
  form.filters.browser_language.allow
		= campaignStore.campaign.filters.browser_language?.allow ?? false

  form.filters.browser.allow = campaignStore.campaign.filters.browser?.allow ?? true
  form.filters.browser.browsers = campaignStore.campaign.filters.browser?.browsers ?? []

  form.active = campaignStore.campaign.active
  form.alias = campaignStore.campaign.alias
  form.domain = campaignStore.campaign.domain
}, {
  immediate: true,
})
</script>

<template>
  <section>
    <div class="space-y-5">
      <CampaignBasicForm
        v-if="form.mode === 'basic'"
        :is-loading="isSaving"
        :errors="errors"
        :campaign="form"
        @submit="updateCampaign(campaignStore.campaign?.id, $event)"
      />

      <CampaignAdvancedForm
        v-else-if="form.mode === 'advanced'"
        :is-loading="isSaving"
        :errors="errors"
        :campaign="form"
        @submit="updateCampaign(campaignStore.campaign?.id, $event)"
      />
    </div>
  </section>
</template>
