<script setup>
import { CopyIcon } from '@radix-icons/vue'
import { toast } from 'vue-sonner'
import { useClipboard } from '@vueuse/core'
import { CheckIcon } from '@heroicons/vue/24/solid'
import { watch } from 'vue'
import Button from '@/components/ui/button/Button.vue'

defineProps({
  content: {
    type: String,
    required: true
  }
})

const { copy, copied } = useClipboard()

watch(copied, (value) => {
  if (value) {
    toast.success('Copiado!')
  }
})
</script>

<template>
  <Button
    variant="ghost"
    size="xs"
    @click="copy(content)"
  >
    <CheckIcon
      v-if="copied"
      class="size-4"
    />
    <CopyIcon
      v-else
      class="size-4"
    />
  </Button>
</template>
