<script setup>
import { computed } from 'vue'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import Card from '@/components/ui/card/Card.vue'
import CopyButton from '@/components/common/CopyButton.vue'

const props = defineProps({
  name: {
    type: String,
    default: 'DomainCnameTable',
  },
})

const cname = computed(() => {
  return window.location.href.includes('cloakup.dev') ? 'domains.cloakup.dev' : 'domains.cloakup.me'
})

const subDomain = computed(() => {
  return props.name.split('.')[0]
})
</script>

<template>
  <Card>
    <Table>
      <TableHeader>
        <TableRow class="border-0">
          <TableHead class="w-[100px]">
            Type
          </TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Value</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>
            CNAME
          </TableCell>
          <TableCell>
            <div class="flex items-center gap-2 ">
              <span class="notranslate">{{ subDomain }}</span>
              <CopyButton :content="subDomain" />
            </div>
          </TableCell>
          <TableCell>
            <div class="flex items-center gap-2">
              <span class="notranslate">
                {{ cname }}
              </span>
              <CopyButton :content="cname" />
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Card>
</template>
