import { CreditCardIcon } from '@heroicons/vue/24/solid'
import MasterCardIcon from '@/components/icons/MasterCardIcon.vue'
import VisaIcon from '@/components/icons/VisaIcon.vue'

export function getBrandSVG(brand) {
  if (!brand) {
    console.assert(brand, 'Brand is required')
    return
  }
  const lowerCase = brand.toLowerCase()
  switch (lowerCase) {
    case 'mastercard':
      return 'mastercard.svg'
    case 'visa':
      return 'visa.svg'
    case 'amex':
      return 'amex.svg'
    case 'diners':
      return 'diners.svg'
    case 'discover':
      return 'discover.svg'
    case 'hipercard':
      return 'hipercard.svg'
    case 'elo':
      return 'elo.svg'
    default:
      return null
  }
}

export function getBrandIcon(brand) {
  if (!brand) {
    console.assert(brand, 'Brand is required')
    return
  }
  const lowerCase = brand.toLowerCase()
  switch (lowerCase) {
    case 'mastercard':
      return MasterCardIcon
    case 'visa':
      return VisaIcon
    default:
      return CreditCardIcon
  }
}
