<script setup>
import { ref, watch } from 'vue'
import { PlusIcon } from '@heroicons/vue/24/solid'
import BaseSpinner from '@/components/base/BaseSpinner.vue'
import { cardService } from '@/services/card'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'
import { getBrandIcon } from '@/helpers/get-brand-svg'

const props = defineProps({
  errors: {
    default: null
  },
  cards: {
    default: []
  }
})

const model = defineModel({
  required: true
})

const isLoading = ref(false)
const hasError = ref(false)
const cards = ref(props.cards)

async function getAllCards() {
  isLoading.value = true
  hasError.value = false
  try {
    const { data } = await cardService.findAll({
      page: 1,
      limit: 50,
      gateway: 'stripe'
    })
    cards.value = [...cards.value, ...data.data]

    if (cards.value.length === 0) {
      model.value = 'new'
      return
    }

    const defaultCard = cards.value.find(card => card.default)
    if (defaultCard) {
      model.value = defaultCard?.token
    } else {
      model.value = cards.value[0]?.token
    }
  } catch (error) {
    hasError.value = true
  } finally {
    isLoading.value = false
  }
}

getAllCards()

watch(
  () => props.cards,
  () => {
    cards.value = [...cards.value, ...props.cards]
  }
)
</script>

<template>
  <div>
    <div
      v-if="isLoading"
      class="flex flex-col items-center justify-center rounded-lg bg-background py-12"
    >
      <div>
        <BaseSpinner />
      </div>
    </div>
    <div
      v-else-if="hasError"
      class="flex flex-col items-center justify-center rounded-lg bg-red-100 py-12"
    >
      <span class="text-sm font-semibold text-red-500">
        {{ $t('components.available_cards.failed_to_load') }}
      </span>
    </div>
    <div v-else>
      <div>
        <ul
          role="list"
          class="space-y-3"
        >
          <ToggleGroup
            v-model="model"
            type="single"
            class="flex w-full flex-col"
          >
            <ToggleGroupItem
              value="new"
              class="flex w-full justify-start rounded-lg border p-4 sm:p-6"
            >
              <div class="flex items-center gap-2">
                <div>
                  <PlusIcon
                    class="h-8 w-auto sm:h-6 sm:shrink-0"
                    aria-hidden="true"
                  />
                </div>
                <div class="sm:ml-4">
                  <div class="text-sm font-medium">
                    {{ $t('components.available_cards.add_card') }}
                  </div>
                </div>
              </div>
            </ToggleGroupItem>
            <li
              v-for="card in cards"
              :key="card.token"
              class="w-full flex-1"
            >
              <ToggleGroupItem
                :value="card.token"
                class="flex w-full justify-start rounded-lg border p-4 sm:p-6"
              >
                <div class="flex items-center gap-2">
                  <div>
                    <component
                      :is="getBrandIcon(card.brand)"
                      class="h-8 w-auto rounded-md sm:h-6 sm:shrink-0 "
                    />
                  </div>
                  <div class="sm:ml-4">
                    <div class="text-sm font-medium">
                      {{ $t('ending_with') }} {{ card.last_four_digits }}
                    </div>
                  </div>
                </div>
              </ToggleGroupItem>
            </li>
          </ToggleGroup>
        </ul>
      </div>
    </div>
  </div>
</template>
