import { cva } from 'class-variance-authority'

export { default as Badge } from './Badge.vue'

export const badgeVariants = cva(
  'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-muted-foreground ring-foreground/10 hover:bg-secondary/80',
        destructive:
          'bg-red-50 text-red-600 ring-red-500/10 dark:bg-red-500/10 dark:text-red-400 dark:ring-red-500/20',
        success:
          'bg-green-50 text-green-600 ring-green-500/10 dark:bg-green-500/10 dark:text-green-400 dark:ring-green-500/20',
        warning:
          'bg-yellow-50 text-yellow-600 ring-yellow-500/10 dark:bg-yellow-500/10 dark:text-yellow-400 dark:ring-yellow-500/20',
        info:
          'bg-blue-50 text-blue-600 ring-blue-500/10 dark:bg-blue-500/10 dark:text-blue-400 dark:ring-blue-500/20',
        outline: 'text-foreground',
        sky: 'bg-sky-50 text-sky-600 ring-sky-500/10 dark:bg-sky-500/10 dark:text-sky-400 dark:ring-sky-500/20',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)
